<template>
  <tenant-settings-provider v-slot="{ save, saving, fetching, settings }">
    <div>
      <page-header title="Локализация" class="mb-8">
        <template v-slot:right>
          <v-btn @click="() => save(settings)" color="success" :disabled="saving">
            <v-icon small left>mdi-content-save</v-icon>
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <div v-if="fetching">
        Загрузка...
      </div>
      <div v-else-if="settings">
        <v-row>
          <v-col>
            Доступные локали
            <SettingsLocalesSelect v-model="settings.locales" />
          </v-col>
          <v-col>
            Локаль по умолчанию
            <SettingsLangSelect v-model="settings.defaultLocale" disabled />
          </v-col>
          <v-col>
            Часовой пояс
            <SettingsTimezoneSelect v-model="settings.timezone" disabled />
          </v-col>
        </v-row>
      </div>
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '../Tenant/TenantSettingsProvider.vue'
import PageHeader from '@/components/PageHeader.vue'
import SettingsLangSelect from './SettingsLangSelect.vue'
import SettingsTimezoneSelect from './SettingsTimezoneSelect.vue'
import SettingsLocalesSelect from './SettingsLocalesSelect.vue'

export default {
  components: {
    SettingsLangSelect,
    SettingsTimezoneSelect,
    SettingsLocalesSelect,
    PageHeader,
    TenantSettingsProvider
  }
}
</script>
