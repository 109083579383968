var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tenant-settings-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var save = ref.save;
var saving = ref.saving;
var fetching = ref.fetching;
var settings = ref.settings;
return [_c('div',[_c('page-header',{staticClass:"mb-8",attrs:{"title":"Мета-теги"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"color":"success","disabled":saving},on:{"click":function () { return save(settings); }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(saving ? 'Сохранение...' : 'Сохранить')+" ")],1)]},proxy:true}],null,true)}),(fetching)?_c('div',[_vm._v(" Загрузка... ")]):(settings)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Favicon "),_c('image-select-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var choose = ref.choose;
var remove = ref.remove;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"border-radius":"8px","padding":"8px 12px","background":"#f7f7f7"}},[_c('img',{staticStyle:{"width":"32px","height":"32px","object-fit":"cover","background-color":"#eee"},attrs:{"src":settings.favicon ? settings.favicon.url : null}}),_c('v-btn',{staticStyle:{"margin-left":"8px"},attrs:{"small":"","icon":"","title":"Удалить"},on:{"click":remove}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-close")])],1),_c('v-btn',{staticStyle:{"margin":"0 0 0 auto"},attrs:{"small":""},on:{"click":choose}},[_vm._v("Выбрать")])],1)]}}],null,true),model:{value:(settings.favicon),callback:function ($$v) {_vm.$set(settings, "favicon", $$v)},expression:"settings.favicon"}})],1)],1),_c('v-row',[_c('v-col',[_c('locale-field',{attrs:{"component":"v-text-field","label":"Название сайта","rules":"required","outlined":"","dense":"","name":"title","hide-details":""},model:{value:(settings.meta.title),callback:function ($$v) {_vm.$set(settings.meta, "title", $$v)},expression:"settings.meta.title"}}),_c('locale-field',{staticClass:"mt-4",attrs:{"component":"v-textarea","auto-grow":"","rows":2,"outlined":"","rules":"required","hide-details":"","name":"description","placeholder":"Description","label":"Описание","dense":""},model:{value:(settings.description),callback:function ($$v) {_vm.$set(settings, "description", $$v)},expression:"settings.description"}})],1),_c('v-col',[_c('share-image-editor',_vm._b({attrs:{"name":"ogImage"},on:{"input:image":function($event){settings.openGraphImage = $event}}},'share-image-editor',{
              title: settings.meta.title ? settings.meta.title.ru : null,
              description: settings.meta.description ? settings.meta.description.ru : null,
              image: settings.openGraphImage
            },false))],1)],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }