<template>
  <tenant-settings-provider v-slot="{ save, saving, fetching, settings }">
    <div>
      <page-header title="Tenant" class="mb-8">
        <template v-slot:right>
          <v-btn @click="() => save(settings)" color="success" :disabled="saving">
            <v-icon small left>mdi-content-save</v-icon>
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <div v-if="fetching">
        Загрузка...
      </div>
      <div v-else-if="settings">
        <v-row>
          <v-col cols="6">
            <locale-field
              component="v-text-field"
              v-model="settings.title"
              label="Tenant name"
              hide-details
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <locale-field
              component="v-text-field"
              v-model="settings.shortTitleWithPlace"
              label="Tenant name with place"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-text-field
              v-model="settings.targetTenantName"
              name="targetTenantName"
              label="targetTenantName"
              outlined
              dense
              placeholder="targetTenantName"
            />
          </v-col>
        </v-row>
      </div>

    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '../Tenant/TenantSettingsProvider.vue'
import PageHeader from '@/components/PageHeader.vue'
import LocaleField from '../Locale/LocaleField.vue'

export default {
  components: {
    PageHeader,
    LocaleField,
    TenantSettingsProvider
  }
}
</script>
