<template>
  <tenant-settings-provider v-slot="{ save, saving, fetching, settings }">
    <div>
      <page-header title="Мета-теги" class="mb-8">
        <template v-slot:right>
          <v-btn @click="() => save(settings)" color="success" :disabled="saving">
            <v-icon small left>mdi-content-save</v-icon>
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <div v-if="fetching">
        Загрузка...
      </div>
      <div v-else-if="settings">
        <v-row>
          <v-col cols="4">
            Favicon
            <image-select-provider v-model="settings.favicon" v-slot="{ choose, remove }">
              <div class="d-flex align-center" style="border-radius: 8px; padding: 8px 12px; background: #f7f7f7">
                <img
                  :src="settings.favicon ? settings.favicon.url : null"
                  style="width: 32px; height: 32px; object-fit: cover; background-color: #eee"
                />
                <v-btn @click="remove" small icon title="Удалить" style="margin-left: 8px"><v-icon x-small>fa-close</v-icon></v-btn>
                <v-btn @click="choose" small style="margin: 0 0 0 auto">Выбрать</v-btn>
              </div>
            </image-select-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <locale-field
              component="v-text-field"
              v-model="settings.meta.title"
              label="Название сайта"
              rules="required"
              outlined
              dense
              name="title"
              hide-details
            />
            <locale-field
              component="v-textarea"
              class="mt-4"
              auto-grow
              :rows="2"
              outlined
              rules="required"
              hide-details
              name="description"
              placeholder="Description"
              label="Описание"
              v-model="settings.description"
              dense
            />
          </v-col>
          <v-col>
            <share-image-editor
              name="ogImage"
              v-bind="{
                title: settings.meta.title ? settings.meta.title.ru : null,
                description: settings.meta.description ? settings.meta.description.ru : null,
                image: settings.openGraphImage
              }"
              @input:image="settings.openGraphImage = $event"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '../Tenant/TenantSettingsProvider.vue'
import PageHeader from '@/components/PageHeader.vue'
import LocaleField from '../Locale/LocaleField.vue'
import ShareImageEditor from '@/components/Share/ShareImageEditor.vue'
import ImageSelectProvider from '@/components/elements/image-select-provider.vue'

export default {
  components: {
    ImageSelectProvider,
    ShareImageEditor,
    PageHeader,
    LocaleField,
    TenantSettingsProvider
  }
}
</script>
