var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tenant-settings-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var save = ref.save;
var saving = ref.saving;
var fetching = ref.fetching;
var settings = ref.settings;
return [_c('div',[_c('page-header',{staticClass:"mb-8",attrs:{"title":"Вид"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"color":"success","disabled":saving},on:{"click":function () { return save(settings); }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(saving ? 'Сохранение...' : 'Сохранить')+" ")],1)]},proxy:true}],null,true)}),(fetching)?_c('div',[_vm._v(" Загрузка... ")]):(settings)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Лого для десктопа")]),_c('ImagePicker',{attrs:{"crop":"contain"},model:{value:(settings.logo),callback:function ($$v) {_vm.$set(settings, "logo", $$v)},expression:"settings.logo"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Лого для мобилы")]),_c('ImagePicker',{attrs:{"crop":"contain"},model:{value:(settings.logoCompact),callback:function ($$v) {_vm.$set(settings, "logoCompact", $$v)},expression:"settings.logoCompact"}})],1),_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v(" Фичи ")]),_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":("Баннер " + (settings.features.banner.enabled ? 'активен' : 'неактивен'))},model:{value:(settings.features.banner.enabled),callback:function ($$v) {_vm.$set(settings.features.banner, "enabled", $$v)},expression:"settings.features.banner.enabled"}}),_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Ч/б фотографии докторов"},model:{value:(settings.grayscaleDoctorImages),callback:function ($$v) {_vm.$set(settings, "grayscaleDoctorImages", $$v)},expression:"settings.grayscaleDoctorImages"}})],1),_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v(" Палитра ")]),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex",staticStyle:{"grid-gap":"12px"}},[_c('span',[_vm._v("Accent color")]),_c('v-icon',_vm._g(_vm._b({style:({ color: settings.colorVariables.accentColor })},'v-icon',attrs,false),on),[_vm._v("fa-circle")])],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"mode":"hexa","value":settings.accentColor},on:{"update:color":function (ref) {
	var hex = ref.hex;

	return settings.colorVariables.accentColor = hex;
}}})],1)],1)],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }