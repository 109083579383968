<template>
  <tenant-settings-provider v-slot="{ save, saving, fetching, settings }">
    <div>
      <page-header title="Вид" class="mb-8">
        <template v-slot:right>
          <v-btn @click="() => save(settings)" color="success" :disabled="saving">
            <v-icon small left>mdi-content-save</v-icon>
            {{ saving ? 'Сохранение...' : 'Сохранить' }}
          </v-btn>
        </template>
      </page-header>

      <div v-if="fetching">
        Загрузка...
      </div>
      <div v-else-if="settings">
        <v-row>
          <v-col cols=3>
            <div class="text-h5">Лого для десктопа</div>
            <ImagePicker crop="contain" v-model="settings.logo" />
          </v-col>

          <v-col cols=3>
            <div class="text-h5">Лого для мобилы</div>
            <ImagePicker crop="contain" v-model="settings.logoCompact" />
          </v-col>

          <v-col>
            <div class="text-h5">
              Фичи
            </div>
            <v-checkbox
              dense
              hide-details
              v-model="settings.features.banner.enabled"
              :label="`Баннер ${settings.features.banner.enabled ? 'активен' : 'неактивен'  }`"
            />
            <v-checkbox
              dense
              hide-details
              v-model="settings.grayscaleDoctorImages"
              label="Ч/б фотографии докторов"
            />
          </v-col>

          <v-col>
            <div class="text-h5">
              Палитра
            </div>
            <v-menu :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <div class="d-flex" style="grid-gap: 12px">
                  <span>Accent color</span>
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :style="{ color: settings.colorVariables.accentColor }"
                  >fa-circle</v-icon>
                </div>
              </template>
              <v-color-picker
                mode="hexa"
                :value="settings.accentColor"
                @update:color="({ hex }) => settings.colorVariables.accentColor = hex"
              />
            </v-menu>
          </v-col>
        </v-row>


      </div>
    </div>
  </tenant-settings-provider>
</template>

<script>
import TenantSettingsProvider from '../Tenant/TenantSettingsProvider.vue'
import PageHeader from '@/components/PageHeader.vue'
import { ImagePicker } from '@/shared/ui'

export default {
  components: {
    ImagePicker,
    PageHeader,
    TenantSettingsProvider
  }
}
</script>
