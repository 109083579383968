<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab v-for="tab in tabs" :key="tab.key" :disabled="tab.disabled">
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.key">
        <component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SettingsWebMenu from '@/components/Settings/SettingsWebMenu.vue'
import SettingsWebMenuHotmenu from '../../components/Settings/SettingsWebMenuHotmenu.vue'
import SettingsVariables from '@/components/Settings/SettingsVariables.vue'
import SettingsTheme from '@/components/Settings/SettingsTheme.vue'
import SettingsTenant from '@/components/Settings/SettingsTenant.vue'
import SettingsMeta from '@/components/Settings/SettingsMeta.vue'
import SettingsLocale from '@/components/Settings/SettingsLocale.vue'

export default {
  data () {
    return {
      tab: null
    }
  },
  computed: {
    tabs () {
      return [
        {
          text: 'Мета-теги',
          key: 'meta',
          component: SettingsMeta,
        },
        {
          text: 'Меню',
          key: 'menu',
          component: SettingsWebMenu
        },
        {
          text: 'Переменные',
          key: 'variables',
          component: SettingsVariables
        },
        {
          text: 'Вид',
          key: 'theme',
          component: SettingsTheme
        },
        {
          text: 'Tenant',
          key: 'tenant',
          component: SettingsTenant,
        },
        {
          text: 'Быстрое меню',
          key: 'hotmenu',
          component: SettingsWebMenuHotmenu,
          disabled: true
        },
        {
          text: 'Локализация',
          key: 'locale',
          component: SettingsLocale,
          disabled: true
        },
      ]
    }
  }
}
</script>
